export type Nullable<T> = T | null | undefined;

export enum MODALS {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'changePassword',
  REMOVE_POSITION = 'removePosition',
  NEW_SIGNAL = 'newSignal',
  REMOVE_DELAYED_SIGNAL = 'removeDelayedSignal',
  ANALYTIC = 'analytic',
  REMOVE_ANALYTIC = 'removeAnalytic',
  CHANGE_POSITION = 'changePosition',
  ADD_USER = 'addUser',
  REMOVE_USER = 'removeUser',
  BANNER = 'banner',
  REMOVE_BANNER = 'removeBanner',
  SIGNAL_APPROVED = 'signalApproved',
  UPLOAD_TRACK = 'uploadTrack',
  ADD_STRATEGY = 'addStrategy',
  OPEN_STRATEGY = 'openStrategy',
  ADD_INVESTBOX = 'addInvestbox',
  UPDATE_HISTORY = 'updateHistory',
  ACCOUNT_REBALANCE = 'accountRebalance',
  ACCOUNT_CALC_REBALANCE = 'accCalcRebalance',
  ACCOUNT_BLOCK = 'accountBlock',
  SERVICE_STOP_CS = 'serviceStop',
  SPLIT = 'split',
  SCHEDULE_EDIT = 'scheduleEdit',
  SCHEDULE_REMOVE = 'scheduleRemove',
  SCHEDULE_REMOVE_WEEKEND = 'scheduleRemoveWeekend',
  SCHEDULE_ADD = 'scheduleAdd',
  SCHEDULE_ADD_WEEKEND = 'scheduleAddWeekend',
  FAQ_ADD_CATEGORY = 'faqAddCategory',
  FAQ_DELETE_CATEGORY = 'faqDeleteCategory',
  FAQ_ADD_QUESTION = 'faqAddQuestion',
  FAQ_DELETE_QUESTION = 'faqDeleteQuestion',
  COMMENT = 'comment',
  API_KEYS = 'apiKeys',
}
