/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import { faArrowDown, faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { useLoadSignals } from './queries';
import { SortType } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { modalApi } from '../../../effector/modals';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { DelayedSignal, DelayedSignalType } from '../../../types/delayedSignal';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';

type Props = {
  id: string;
  isChild: boolean;
};

export const Delayed: React.FC<Props> = ({ id, isChild }) => {
  const user = useStore(User$);
  const strategies = useStore(Strategies$);

  const strategy = useMemo(() => strategies.find((item) => item.id === id), [id, strategies]);

  const data = useMemo(
    () => ({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'time',
      sortDirection: SortType.Desk,
    }),
    [],
  );

  const { data: signals } = useLoadSignals(id, strategy?.status, data);

  const columns = useMemo<MRT_ColumnDef<DelayedSignal>[]>(() => {
    if (!user) {
      return [];
    }

    const items: (MRT_ColumnDef<DelayedSignal> & { action: UserAction; isChild: boolean })[] = [
      {
        id: 'profit',
        header: '',
        accessorKey: 'weight',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { weight },
            },
          },
        }) => {
          const isUp = weight > 0;
          const icon = isUp ? faArrowUp : faArrowDown;

          return <FontAwesomeIcon className={cn({ fall: !isUp, rise: isUp })} icon={icon} />;
        },
        size: 20,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Инструмент',
        accessorKey: 'name',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { name, symbol, comment },
            },
          },
        }) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // tslint:disable-next-line: jsx-wrap-multiline
            <Tooltip theme="light" title={comment} arrow={true} position="top" trigger="mouseenter">
              <span>
                {name} ({symbol})
              </span>
            </Tooltip>
          );
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Доля %',
        accessorKey: 'weight',
        action: UserAction.strategy,
        isChild: true,
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Цена',
        accessorKey: 'execPrice',
        action: UserAction.strategy,
        isChild: true,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Время открытия',
        accessorKey: 'time',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { fillingTime },
            },
          },
        }) => {
          const timeFormated = moment(fillingTime).format('DD.MM.YYYY HH:mm');

          return <span>{timeFormated}</span>;
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Статус',
        accessorKey: 'type',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { type },
            },
          },
        }) => {
          return type === DelayedSignalType.Standard ? 'Ждёт исполнения' : 'Ждёт активации';
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Текущая цена',
        accessorKey: 'currentPrice',
        action: UserAction.strategy,
        isChild: true,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'SL',
        accessorKey: 'stopLoss',
        action: UserAction.strategy,
        isChild: true,
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'TP',
        accessorKey: 'takeProfit',
        action: UserAction.strategy,
        isChild: true,
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        id: 'remove',
        header: '',
        accessorKey: 'id',
        action: UserAction.strategyPositionRemove,
        isChild: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faTimes}
                className="fall"
                onClick={() =>
                  modalApi.show({
                    modalId: MODALS.REMOVE_DELAYED_SIGNAL,
                    data: {
                      signalId: original.id,
                    },
                  })
                }
              />
            </span>
          );
        },
        size: 20,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
    ];

    const filteredColumns = items.filter((item) => checkPermissions(user.role, item.action as UserAction));

    return isChild ? filteredColumns.filter((item) => item.isChild) : filteredColumns;
  }, [isChild, user]);

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'time',
      },
    ],
    [],
  );

  return (
    <div style={{ marginTop: '30px' }}>
      <TableGridLocal
        columns={columns}
        data={signals?.result?.pageSignals || []}
        pageSize={25}
        columnSorts={columnSorts}
        totalRowCount={signals?.result?.totalSignals || 0}
      />
    </div>
  );
};
