/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore, createApi, createEffect, forward } from 'effector';

import { getBranches } from '../api';
import { Option } from '../types/option';
import { Nullable } from '../utils/types';

type TBranch$State = Nullable<Option[]>;

export const BranchDictionary$ = createStore<TBranch$State>(null);

const { set } = createApi(BranchDictionary$, {
  // @ts-ignore
  set: (state, { result }) => result.result?.map(({ code, name }) => ({ id: code, name })),
});

export const branchDictionaryApi = {
  get: createEffect().use(getBranches),
};

forward({
  from: branchDictionaryApi.get.done,
  to: set,
});
