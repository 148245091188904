/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import { faEdit, faPlus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';

import { getBanners } from '../../api';
import { SortType } from '../../components/TableGrid';
import { TableGridLocal } from '../../components/TableGridLocal';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { modalApi } from '../../effector/modals';
import { Banner } from '../../types/banner';
import { MODALS } from '../../utils/types';

export const Banners: React.FC = () => {
  const { data: banners } = useQuery('getBanners', () =>
    getBanners({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'name',
      sortDirection: SortType.Desk,
    }),
  );

  const onAddBanner = () => {
    modalApi.show({
      modalId: MODALS.BANNER,
    });
  };

  const columns = useMemo<MRT_ColumnDef<Banner>[]>(
    () => [
      {
        header: 'Заголовок',
        accessorKey: 'title',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Подзаголовок',
        accessorKey: 'subtitle',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Текст кнопки',
        accessorKey: 'buttonText',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Ссылка',
        accessorKey: 'url',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'UTM-метка',
        accessorKey: 'utm',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Статус',
        accessorKey: 'active',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: {
              original: { active },
            },
          },
        }) => {
          const name = active ? 'Активный' : 'Неактивный';

          return <span>{name}</span>;
        },
      },
      {
        header: '',
        accessorKey: 'id',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.BANNER,
                    data: {
                      banner: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
      {
        header: '',
        accessorKey: 'remove',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className="fall"
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.REMOVE_BANNER,
                    data: {
                      banner: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddBanner}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          columns={columns}
          data={banners?.result?.pageItems || []}
          pageSize={15}
          totalRowCount={banners?.result?.total || 0}
        />
      </div>
    </>
  );
};
