import { useCallback, useEffect, useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_TableInstance,
  type MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
} from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import moment from 'moment';

import { getFilterMatches } from './filters';
import { isValidDate } from '../../utils';
import { Criteria, PageParams, SortType } from '../TableGrid';

const exportToCSV = (text: string) => {
  const hiddenElement = document.createElement('a');
  const date = new Date();
  hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
  hiddenElement.download = `${date.toISOString()}.csv`;
  hiddenElement.click();
};

type Props = {
  columns: any[];
  data: any;
  columnSorts?: MRT_SortingState;
  columnFilters?: MRT_ColumnFiltersState;
  columnVisibility?: Record<string, boolean>;
  pageSize?: number;
  totalRowCount: number;
  serverMode?: boolean;
  onFetchData?: (params: PageParams) => void;
};

export const TableGridLocal: React.FC<Props> = ({
  columns,
  data,
  onFetchData,
  serverMode = false,
  totalRowCount = 0,
  columnSorts = [],
  columnFilters = [],
  columnVisibility = {},
  pageSize = 10,
}) => {
  const [sorting, setSorting] = useState<MRT_SortingState>(columnSorts);
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>(columnFilters);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const handleFetchData = useCallback(() => {
    if (serverMode && onFetchData) {
      const sort = sorting.length > 0 ? sorting[0] : { id: '', desc: false };

      onFetchData({
        pageNumber: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sortFieldName: sort.id,
        sortDirection: sort.desc ? SortType.Desk : SortType.Ask,
        filters: filters.map((filter) => ({
          fieldName: filter.id,
          filterCriteria: Array.isArray(filter.value) && filter.value.length ? Criteria.Equals : Criteria.Contains,
          matches: getFilterMatches(filter.value),
        })),
      });
    }
  }, [serverMode, onFetchData, sorting, pagination, filters]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  const handleDownload = useCallback(async (table: MRT_TableInstance<any>) => {
    const lastHeaderGroup = table.getHeaderGroups().at(-1);
    if (!lastHeaderGroup) {
      console.error('Заголовки таблицы не найдены: ', table.getHeaderGroups());
      return [];
    }

    const headers = lastHeaderGroup.headers
      .filter((h) => h.column.getIsVisible())
      .filter((h) => Boolean(h.column.columnDef.header))
      .map((header) => {
        return header.column.columnDef.header as string;
      });

    const exportRows = table.getSortedRowModel().rows;

    const data = exportRows.map((row) => {
      const cells = row.getVisibleCells().filter((cell) => Boolean(cell.column.columnDef.header));
      const values = cells.map((cell) => {
        const value = cell.getValue();
        if (typeof value !== 'number' && isValidDate(value as string)) {
          return moment(value as string).format('DD.MM.YYYY HH:mm');
        }

        return cell.getValue() ?? '';
      });

      return values.join(', ');
    });

    const th = headers.join(', ');
    const td = data.join('\n');

    const text = `${th}\n${td}`;
    exportToCSV(text);
  }, []);

  const table = useMaterialReactTable({
    columns,
    data,
    localization: MRT_Localization_RU,
    columnFilterDisplayMode: 'popover',
    manualSorting: serverMode,
    manualPagination: serverMode,
    manualFiltering: serverMode,
    pageCount: Math.ceil(totalRowCount / pagination.pageSize),
    rowCount: totalRowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
    initialState: {
      showColumnFilters: true,
      density: 'compact',
      columnVisibility,
      columnFilters: filters,
      pagination,
      sorting,
    },
    state: {
      columnFilters: filters,
      pagination,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getFilteredRowModel().rows.length === 0}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => handleDownload(table)}
          startIcon={<FileDownloadIcon />}
        >
          Скачать CSV
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
