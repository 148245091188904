import React, { useMemo } from 'react';

import { faArrowDown, faArrowUp, faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { Tooltip } from 'react-tippy';

import { useLoadPositions } from './queries';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { modalApi } from '../../../effector/modals';
import { Strategies$ } from '../../../effector/strategies';
import { User$ } from '../../../effector/user';
import { Position } from '../../../types/position';
import { RecalculationMode, StrategyStatus } from '../../../types/strategy';
import { checkPermissions, UserAction } from '../../../utils/permissions';
import { MODALS } from '../../../utils/types';

type Props = {
  id: string;
  isChild: boolean;
};

export const Positions: React.FC<Props> = ({ id, isChild }) => {
  const user = useStore(User$);
  const strategies = useStore(Strategies$);

  const strategy = useMemo(() => strategies.find((item) => item.id === id), [id, strategies]);

  const columns = useMemo<MRT_ColumnDef<Position>[]>(() => {
    if (!user) {
      return [];
    }

    const items: (MRT_ColumnDef<Position> & { action: UserAction; isChild: boolean; hide?: boolean })[] = [
      {
        id: 'profit',
        header: '',
        accessorKey: 'weight',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { weight },
            },
          },
        }) => {
          const isUp = Number(weight) > 0;
          const icon = isUp ? faArrowUp : faArrowDown;

          return <FontAwesomeIcon className={cn({ fall: !isUp, rise: isUp })} icon={icon} />;
        },
        size: 20,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        header: 'Инструмент',
        accessorKey: 'symbol',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { name, symbol },
            },
          },
        }) => (
          <span>
            {name} ({symbol})
          </span>
        ),
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Доля %',
        accessorKey: 'weight',
        action: UserAction.strategy,
        isChild: true,
        hide: strategy?.recalcMode === RecalculationMode.CurrentPriceWithRecalculation,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Цена открытия',
        accessorKey: 'openPrice',
        action: UserAction.strategy,
        size: 120,
        isChild: true,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Время открытия',
        accessorKey: 'openTime',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { openTime },
            },
          },
        }) => {
          const time = moment(openTime).format('DD.MM.YYYY HH:mm');

          return <span>{time}</span>;
        },
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Текущая цена',
        accessorKey: 'currentPrice',
        action: UserAction.strategy,
        size: 120,
        isChild: true,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Текущая доля %',
        accessorKey: 'currentWeight',
        action: UserAction.strategyPositionCurrentShare,
        isChild: true,
        hide:
          strategy?.recalcMode === RecalculationMode.WeightedAverageWithRecalculation ||
          strategy?.recalcMode === RecalculationMode.WeightedAverageWithoutRecalculation,
        size: 150,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'SL',
        accessorKey: 'stopLoss',
        action: UserAction.strategy,
        isChild: true,
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'TP',
        accessorKey: 'takeProfit',
        action: UserAction.strategy,
        isChild: true,
        size: 60,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Realized PnL %',
        accessorKey: 'realizedPL',
        action: UserAction.strategy,
        isChild: true,
        size: 150,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Unealized PnL %',
        accessorKey: 'unrealizedPL',
        action: UserAction.strategy,
        isChild: true,
        Cell: ({
          cell: {
            row: {
              original: { unrealizedPL, unrealizedPLPortfolio },
            },
          },
        }) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // tslint:disable-next-line: jsx-wrap-multiline
            <Tooltip
              theme="light"
              title={`${unrealizedPL}${unrealizedPLPortfolio && `(${unrealizedPLPortfolio})`}`}
              arrow={true}
              position="right"
              trigger="mouseenter"
            >
              <span
                className={cn({
                  fall: Number(unrealizedPL) < 0,
                  rise: Number(unrealizedPL) > 0,
                })}
              >
                {unrealizedPL}
                {unrealizedPLPortfolio && `(${unrealizedPLPortfolio})`}
              </span>
            </Tooltip>
          );
        },
        size: 150,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        id: 'remove',
        header: '',
        accessorKey: 'securityKey',
        action: UserAction.strategyPositionRemove,
        isChild: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          if (strategy?.status === StrategyStatus.Standard || strategy?.status === StrategyStatus.Closed) {
            return (
              <span className="pointer">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="fall"
                  onClick={() => {
                    modalApi.show({
                      modalId: MODALS.REMOVE_POSITION,
                      data: {
                        position: original,
                        strategyId: id,
                      },
                    });
                  }}
                />
              </span>
            );
          }

          return null;
        },
        size: 20,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        header: '',
        accessorKey: 'change',
        action: UserAction.strategyPositionChange,
        isChild: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className="fall"
                onClick={() => {
                  modalApi.show({
                    modalId: MODALS.CHANGE_POSITION,
                    data: {
                      strategyId: id,
                      position: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
        size: 20,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        enableSorting: false,
      },
    ];

    const filteredColumns = items
      .filter((item) => checkPermissions(user.role, item.action as UserAction))
      .filter((item) => !item.hide);

    const cols = filteredColumns.map(({ action, hide, ...rest }) => rest);

    return isChild ? cols.filter((item) => item.isChild) : cols;
  }, [id, isChild, strategy?.recalcMode, strategy?.status, user]);

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'time',
      },
    ],
    [],
  );

  const { data: positions } = useLoadPositions(id, strategy?.status);

  return (
    <div style={{ marginTop: '30px' }}>
      <TableGridLocal
        columns={columns}
        data={positions?.result || []}
        pageSize={25}
        columnSorts={columnSorts}
        totalRowCount={positions?.result?.length || 0}
      />
    </div>
  );
};
