/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useStore } from 'effector-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import moment from 'moment';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { getProblemClientsStatistic } from '../../../api';
import { Input } from '../../../components/FormControls/Input';
import { Select } from '../../../components/FormControls/Select';
import { Criteria, Filter, PageParams } from '../../../components/TableGrid';
import { TableGridLocal } from '../../../components/TableGridLocal';
import { Button, ButtonType } from '../../../components/UIKit/Button';
import { StatisticFilters$ } from '../../../effector/statisticFilters';
import { Option } from '../../../types/option';
import { ProblemClient } from '../../../types/problemClient';
import { PAGE_STATISTIC_CLIENT_DETAILS_INFO } from '../../Routes';

const validationSchema = Yup.object().shape({
  number: Yup.string().nullable(),
  description: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      name: Yup.string(),
    }),
  ),
});

type FormFields = {
  number?: string;
  description: Option[];
};

type FormProps = {
  onFilterChanged: (filters: Filter) => void;
} & FormikProps<FormFields>;

const initialValues = {
  number: '',
  description: [],
};

const FormTemplate: React.FC<FormProps> = ({ values, resetForm, onFilterChanged }) => {
  const statisticFilters = useStore(StatisticFilters$);
  useEffect(() => {
    const filters = Object.keys(values)
      .map((item) => {
        // @ts-ignore
        const fieldValue = values[item];

        if (typeof fieldValue === 'string' && fieldValue.trim()) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Contains,
            matches: [fieldValue],
          };
        }

        if (typeof fieldValue === 'number' && fieldValue != null) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Equals,
            matches: [fieldValue],
          };
        }

        if (Array.isArray(fieldValue) && fieldValue.length) {
          return {
            fieldName: item,
            filterCriteria: Criteria.Equals,
            matches: fieldValue.map((item) => item.name),
          };
        }
      })
      .filter((item) => !!item);

    // @ts-ignore
    onFilterChanged(filters);
  }, [values]);

  return (
    <Form className="form" noValidate>
      <div className="form-control-row">
        <Field name="number" component={Input} placeholder="Ген. соглашение" />
      </div>
      <div className="form-control-row">
        {statisticFilters.problems && (
          <div className="form-control-group__item">
            <Field
              name="description"
              component={Select}
              multiple={true}
              placeholder="Проблемы"
              options={statisticFilters.problems}
            />
          </div>
        )}
      </div>
      <div className="table-filter__actions">
        <Button className="button__primary button__large" onClick={() => resetForm()} type={ButtonType.reset}>
          Сбросить
        </Button>
      </div>
    </Form>
  );
};

export const ProblemClients: React.FC = () => {
  const [formFilters, setFormFilters] = useState<Filter[]>([]);

  const { data, mutate: getProblemClients } = useMutation('getProblemClientsStatistic', (params: PageParams) =>
    getProblemClientsStatistic(params),
  );

  const onFilterChanged = (filters: Filter[]) => {
    setFormFilters(filters);
  };

  const loadClients = useCallback(
    (params: PageParams) => {
      getProblemClients({
        ...params,
        filters: formFilters.concat(params.filters || []),
      });
    },
    [formFilters, getProblemClients],
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit = () => {};

  const columns = useMemo<MRT_ColumnDef<ProblemClient>[]>(
    () => [
      {
        header: 'Ген. соглашение',
        accessorKey: 'number',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          return (
            <Link to={`${PAGE_STATISTIC_CLIENT_DETAILS_INFO}/${cell?.row?.original?.id}`}>
              {cell?.row?.original?.number}
            </Link>
          );
        },
      },
      {
        header: 'Дата подключения',
        accessorKey: 'bindingDate',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (!cell?.row?.original?.bindingDate) {
            return '';
          }

          return moment(cell?.row?.original?.bindingDate).format('YYYY-MM-DDTHH:mm:ss');
        },
      },
      {
        header: 'Описание',
        accessorKey: 'description',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({ cell }) => <span>{cell?.row?.original?.description}</span>,
      },
    ],
    [],
  );

  const columnSorts = useMemo<MRT_SortingState>(
    () => [
      {
        desc: true,
        id: 'number',
      },
    ],
    [],
  );

  return (
    <>
      <div className="table-filter">
        <div className="table-filter__title">Фильтры</div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {(props) =>
            React.createElement(
              FormTemplate,
              // @ts-ignore
              {
                ...props,
                // @ts-ignore
                onFilterChanged,
              },
            )
          }
        </Formik>
      </div>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          serverMode
          columns={columns}
          data={data?.result?.pageStats || []}
          pageSize={25}
          columnSorts={columnSorts}
          totalRowCount={data?.result?.total || 0}
          onFetchData={loadClients}
        />
      </div>
    </>
  );
};
