/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo } from 'react';

import { faEdit, faPlus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { useQuery } from 'react-query';

import { getAnalytics } from '../../api';
import { SortType } from '../../components/TableGrid';
import { TableGridLocal } from '../../components/TableGridLocal';
import { Button, ButtonType } from '../../components/UIKit/Button';
import { authorDictionaryApi } from '../../effector/authorDictionary';
import { categoryDictionaryApi } from '../../effector/categoryDictionary';
import { modalApi } from '../../effector/modals';
import { Analytic } from '../../types/analytic';
import { MODALS } from '../../utils/types';

export const Analytics: React.FC = ({}) => {
  const { data: analytics } = useQuery('getAnalytics', () =>
    getAnalytics({
      pageNumber: 0,
      pageSize: 1000,
      sortFieldName: 'date',
      sortDirection: SortType.Desk,
    }),
  );

  const onAddAnalytic = () => {
    modalApi.show({
      modalId: MODALS.ANALYTIC,
    });
  };

  const columns = useMemo<MRT_ColumnDef<Analytic>[]>(
    () => [
      {
        header: 'Заголовок',
        accessorKey: 'title',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Стратегия',
        accessorKey: 'strategyId',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
      },
      {
        header: 'Время публикации',
        accessorKey: 'date',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { date },
            },
          },
        }) => {
          const timeFormatted = moment(date).format('DD.MM.YYYY HH:mm');

          return <span>{timeFormatted}</span>;
        },
      },
      {
        header: 'Автор',
        accessorKey: 'authorId',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { author },
            },
          },
        }) => {
          const name = author?.displayName ?? '';

          return <span>{name}</span>;
        },
      },
      {
        header: 'Статус',
        accessorKey: 'active',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        Cell: ({
          cell: {
            row: {
              original: { active },
            },
          },
        }) => {
          const name = active ? 'Активный' : 'Неактивный';

          return <span>{name}</span>;
        },
      },
      {
        header: '',
        accessorKey: 'id',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.ANALYTIC,
                    data: {
                      analytic: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
      {
        header: '',
        accessorKey: 'remove',
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnFilterModes: false,
        enableColumnActions: false,
        size: 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <span className="pointer">
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className="fall"
                onClick={() => {
                  // @ts-ignore
                  modalApi.show({
                    modalId: MODALS.REMOVE_ANALYTIC,
                    data: {
                      analytic: original,
                    },
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    authorDictionaryApi.get('');
    categoryDictionaryApi.get('');
  }, []);

  return (
    <>
      <Button className="button button__primary button__small" type={ButtonType.button} onClick={onAddAnalytic}>
        <FontAwesomeIcon icon={faPlus} /> Добавить
      </Button>
      <div style={{ marginTop: '30px' }}>
        <TableGridLocal
          columns={columns}
          data={analytics?.result?.pageItems || []}
          pageSize={15}
          totalRowCount={analytics?.result?.total || 0}
        />
      </div>
    </>
  );
};
