export const getCorrectServiceType = (value: string) => {
  if (value === 'Накопилка' || value === 'НК') {
    return 'Накопилка';
  }

  if (value === 'Стратегия АС' || value === 'АС') {
    return 'Автоследование';
  }

  if (value === 'Стратегия АК' || value === 'АК') {
    return 'Автоконсультирование';
  }

  return value;
};

export const getShortServiceType = (value: string): number | string => {
  if (value === 'Накопилка') {
    return 3;
  }

  if (value === 'Автоследование') {
    return 1;
  }

  if (value === 'Автоконсультирование') {
    return 2;
  }

  return value;
};

export const getCorrectCurrencyCod = (value: string): string => {
  if (value === 'RUB') {
    return 'SUR';
  }

  return value;
};

export function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function sortColumnFn(col1, col2, prop) {
  const first = col1.original[prop];

  const second = col2.original[prop];

  return first > second ? 1 : first < second ? -1 : 0;
}

const convertToNumberOrString = (val: unknown): string | number => {
  if (typeof val === 'string') {
    const currency = getCorrectCurrencyCod(val);
    const shortServiceType = getShortServiceType(currency);
    const parsed = Number(shortServiceType);
    return isNaN(parsed) ? shortServiceType : parsed;
  }

  if (typeof val === 'number') {
    return val;
  }

  return String(val);
};

export const getFilterMatches = (value: unknown): (string | number)[] => {
  if (Array.isArray(value)) {
    return value.map(convertToNumberOrString);
  }

  return [convertToNumberOrString(value)];
};
