import React, { useEffect, useMemo } from 'react';

import { useStore } from 'effector-react';
import { Route, Routes as RoutesComponent, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import { Chart } from './Chart';
import { Deals } from './Deals';
import { Delayed } from './Delayed';
import { Portfolio } from './Portfolio';
import { Positions } from './Positions';
import { Track } from './Track';
import { Navigation } from '../../components/Navigation';
import { signalsApi } from '../../effector/signal';
import { Strategies$ } from '../../effector/strategies';
import { User$ } from '../../effector/user';
import { checkPermissions, UserAction } from '../../utils/permissions';
import { PrivateRoute } from '../PrivateRoute';
import {
  PAGE_STRATEGIES,
  PAGE_STRATEGY_DELAYED,
  PAGE_STRATEGY_HISTORY,
  PAGE_STRATEGY_POSITIONS,
  PAGE_STRATEGY_PROFIT,
  PAGE_STRATEGY_TRACK,
} from '../Routes';

const customStyles = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  option: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#43497b',
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  menuPortal: (base) => ({
    ...base,
    zIndex: 3,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  menu: (base) => ({
    ...base,
    zIndex: 3,
  }),
};

export const Strategy: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const user = useStore(User$);
  const strategies = useStore(Strategies$);

  useEffect(() => {
    signalsApi.checkSignal(id as string);
  }, [id]);

  const strategy = strategies.find((item) => item.id === id);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onChange = (val) => {
    navigate(`${PAGE_STRATEGIES}/${val.id}/${PAGE_STRATEGY_POSITIONS}`);
  };

  const items = useMemo(() => {
    const routes = [
      {
        name: 'Открытые позиции',
        path: PAGE_STRATEGY_POSITIONS,
        action: UserAction.strategy,
      },
      {
        name: 'Отложенные сигналы',
        path: PAGE_STRATEGY_DELAYED,
        action: UserAction.strategy,
      },
      {
        name: 'История сделок',
        path: PAGE_STRATEGY_HISTORY,
        action: UserAction.strategy,
      },
      {
        name: 'История доходности',
        path: PAGE_STRATEGY_PROFIT,
        action: UserAction.strategy,
      },
      {
        name: 'Управление треком',
        path: PAGE_STRATEGY_TRACK,
        action: UserAction.sandbox,
      },
    ];

    return routes;
  }, []);

  const navigations = useMemo(() => {
    if (user?.role) {
      return items.filter((item) => checkPermissions(user.role, item.action));
    }
    return [];
  }, [user, items]);

  if (!strategy) {
    return null;
  }

  return (
    <div className="strategies">
      <div className="strategies__selection">
        {strategies?.length > 0 && (
          <Select
            placeholder="Выберите стратегию"
            styles={customStyles}
            options={strategies}
            onChange={onChange}
            getOptionLabel={(option) => option.name}
            defaultValue={strategies.find((item) => item.id === id)}
          />
        )}
      </div>
      <div className="strategies__content">
        <div className="strategies__details">
          <Navigation items={navigations} />
          <div>
            <RoutesComponent>
              <Route
                path={PAGE_STRATEGY_POSITIONS}
                element={<Positions id={strategy?.id} isChild={strategy?.isChild} />}
              />
              <Route path={PAGE_STRATEGY_DELAYED} element={<Delayed id={strategy?.id} isChild={strategy?.isChild} />} />
              <Route path={PAGE_STRATEGY_HISTORY} element={<Deals strategyId={id} />} />
              <Route path={PAGE_STRATEGY_PROFIT} element={<Chart strategyId={id} />} />
              <Route
                path={PAGE_STRATEGY_TRACK}
                element={<PrivateRoute component={Track} userAction={UserAction.sandbox} />}
              />
            </RoutesComponent>
          </div>
        </div>
        <Portfolio id={strategy?.id} isChild={strategy?.isChild} parentStrategy={strategy?.parentStrategy} />
      </div>
    </div>
  );
};
